import Image from "next/image";
import Link from "next/link";
import { SubscriptionTag } from "components/navbar/navbar.style";
import { getSession } from "utils/auth";
import { userModalData } from "./user-modal-data";
import {
   UserWrapper,
   UserIcon,
   NameWrapper,
   DataWrapper,
   UserDropDown,
   OtherMenuLinks,
   UserIconWrapper,
} from "./user-modal.styles";

const UserModal = () => {
   const { user, logout } = getSession();
   const mapPathToIcon = {
      premium: "/images/premium-sub.png",
      enhanced: "/images/enhanced-sub.png",
   };

   return (
      <UserDropDown>
         <UserWrapper>
            <UserIconWrapper>
               <UserIcon>
                  {`${user?.firstName?.charAt(0).toUpperCase()} ${user?.lastName
                     ?.charAt(0)
                     .toUpperCase()}`}
               </UserIcon>
               <SubscriptionTag subType={user.studyAbroadSubscriptionType}>
                  {user.studyAbroadSubscriptionType}
               </SubscriptionTag>
               {user.studyAbroadSubscriptionType != "basic" && (
                  <div className="subscription-icon">
                     <img src={mapPathToIcon[user.studyAbroadSubscriptionType]} alt="icon" />
                  </div>
               )}
            </UserIconWrapper>
            <NameWrapper>
               <h4>
                  {user?.firstName} {user?.lastName}
               </h4>
               <p>{user?.email}</p>
            </NameWrapper>
         </UserWrapper>
         <DataWrapper>
            {userModalData.map((item, index) => {
               if (!item) return;
               return (
                  <Link href={item.link as string} key={index} passHref>
                     <div className="innerLink">
                        <Image
                           src={(item as Record<string, string>).img as any}
                           alt={(item as Record<string, string>).text as any}
                           layout="fixed"
                           width={17}
                           height={17}
                        />
                        <p>{(item as Record<string, string>).text}</p>
                     </div>
                  </Link>
               );
            })}
         </DataWrapper>
         <OtherMenuLinks>
            <Link href="/education-finance" passHref>
               <p>Education Finance</p>
            </Link>
            <Link href="/about-us" passHref>
               <p>About Us</p>
            </Link>
            <Link href="/advisory" passHref>
               <p>Edusko Board</p>
            </Link>
            <Link href="/summer-camp" passHref>
               <p>Summer Camp</p>
            </Link>
            <p onClick={logout} role="presentation" onKeyDown={logout}>
               Logout
            </p>
         </OtherMenuLinks>
      </UserDropDown>
   );
};

export default UserModal;
