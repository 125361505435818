import styled, { css } from "styled-components";

export const NavbarContainer = styled.nav<{ isLandingPage: boolean }>`
   height: 7rem;
   padding: 0 3rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100vw;
   transition: height 300ms ease-in-out;
   z-index: 7000;
   top: 0;
   position: fixed;
   left: 0;
   background-color: transparent;
   border-bottom: 1.5px solid #000;

   ${({ isLandingPage }) =>
      !isLandingPage &&
      css`
         background-color: white;
         position: relative;
         box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      `}

   &.isSticky {
      position: fixed;
      top: 0;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      height: 7rem;
      width: 100%;
      background-color: white;
   }

   .dropdown {
      position: relative;

      p.dropdown-text {
         cursor: pointer;
         line-height: 55px;
         border-radius: 50%;
         height: 48px;
         width: 48px;
         display: flex;
         align-items: center;
         justify-content: center;
         font-size: 15px;
         font-weight: 600;
         color: #fff;
         background-color: ${({ theme }) => theme.colors?.primary};
      }
      .subscription-icon {
         position: absolute;
         top: 0;
         right: 0;
      }
   }

   .dropdown-menu {
      visibility: hidden;
      transform: translateY(10px);
      transition: transform 300ms ease-in-out, opacity 300ms ease-in;
      opacity: 0;
   }

   .dropdown:hover .dropdown-menu {
      visibility: visible;
      opacity: 1;
      transition: all 300ms ease-in-out;
      transform: translateY(5px);
   }
`;

export const SubscriptionTag = styled.div<{ subType: string }>`
   position: absolute;
   bottom: 0;
   right: 0;
   background: ${({ subType }) =>
      subType === "basic" ? "#29b67a" : subType === "premium" ? "#F9A215" : "#214888"};
   font-weight: 400;
   font-size: 8px;
   color: #ffffff;
   padding: 3px;
   width: 100%;
   text-align: center;
   line-height: 10px;
   text-transform: capitalize;
`;

export const NavBarLinks = styled.div<{ menuActive?: boolean }>`
   display: flex;
   justify-content: flex-end;
   height: 100%;

   .bottom-brand {
      display: none;
   }

   @media screen and (max-width: 1190px) {
      display: none;
   }
`;

export const NavLinks = styled.div<{ menuActive?: boolean; notAuth?: boolean }>`
   font-size: 1.5rem;
   display: flex;
   align-items: center;
   padding: 0 15px;

   ${({ notAuth }) =>
      notAuth &&
      css`
         margin-top: 20px;
      `}

   @media screen and (max-width: 1190px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;

      a {
         display: block;
         height: 45px;
         font-size: 1.48rem;
         font-weight: 400;
      }

      ${({ menuActive }) => menuActive && css``}
   }
`;

export const OtherLinks = styled.div<{ menuActive?: boolean }>`
   display: flex;
   align-items: center;
   margin-left: 50px;

   @media screen and (max-width: 1190px) {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0px;
      padding: 0 15px;
      margin-top: 20px;
      a {
         display: block;
      }

      ${({ menuActive }) => menuActive && css``}
   }
`;

export const NavLink = styled.div<{ isLandingPage?: boolean }>`
   text-decoration: none;
   color: #264ffa;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px; /* 150% */
   cursor: pointer;

   ${({ isLandingPage }) =>
      isLandingPage &&
      css`
         color: #fff;
         text-shadow: 1px 1px 2px black;
      `}

   &.isSticky {
      text-shadow: none;
      color: #666;
   }

   &:not(:first-child) {
      margin-left: 35px;

      @media screen and (max-width: 1190px) {
         margin-left: 0;
      }
   }

   &.active {
      color: ${({ theme }) => theme.colors?.primary};
      font-weight: 500;
   }

   &:first-child {
      @media screen and (max-width: 1190px) {
         border-top: 1px solid #e2e1e5;
      }
   }
   @media screen and (max-width: 1190px) {
      width: 100% !important;
      margin-left: 0px;
      border-bottom: 1px solid #e2e1e5;
      padding: 15px;
   }
`;

export const MenuIcon = styled.div`
   /* color: ${({ theme }) => theme.colors?.primary}; */
   display: none;
   cursor: pointer;
   font-size: 2rem;
   z-index: 1500;

   @media screen and (max-width: 1190px) {
      display: block;
      z-index: 1500;
   }
`;

export const BackDrop = styled.div<{ menuActive?: boolean }>`
   visibility: hidden;

   @media screen and (max-width: 1190px) {
      width: 100%;
      height: 100vh;
      position: fixed;
      display: block;
      z-index: 129;
      cursor: pointer;
      background: none;
      transition: background 0.2s ease-in;

      ${({ menuActive }) =>
         menuActive &&
         css`
            visibility: visible;
            background: rgba(0, 0, 0, 0.8);
         `}
   }
`;

export const UserDetailsSection = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin: 0px 0 20px 0;
   position: relative;
   padding: 15px;
   background-color: #f5f5f5;
   cursor: pointer;

   .right-icon {
      font-size: 25px;
      opacity: 0.3;
   }

   .user-content-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
   }

   span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      background: ${({ theme }) => theme.colors?.primary};
      border-radius: 50%;
      color: #fff;
      font-weight: 600;
   }

   .user-info {
      margin-left: 10px;
   }

   .user-name {
      font-weight: 500;
   }

   .greeting {
      font-size: 14px;
      color: ${({ theme }) => theme.colors?.grey02};
      color: #3577e5;
   }
`;

export const MobileNavContainer = styled.div<{ menuActive?: boolean }>`
   display: none;

   @media screen and (max-width: 1190px) {
      background: #fff;
      position: fixed;
      height: calc(100vh - 7rem);
      bottom: 0;
      box-shadow: rgb(0 0 0 / 20%) -4px 20px 10px;
      border: 1px solid rgba(101, 100, 100, 0.1);
      z-index: 214;
      overflow: scroll;
      transition: right 0.3s ease-in-out 0s, opacity 0.4s ease-in-out 0.1s;
      opacity: 0;
      right: -915px;
      backface-visibility: hidden;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: auto;

      .bottom-brand {
         align-self: baseline;
         justify-self: flex-end;
         position: absolute;
         bottom: 0;
         padding: 10px;
      }

      ${({ menuActive }) =>
         menuActive &&
         css`
            right: 0px;
            opacity: 1;
         `}
   }
`;

export const MobileUserProfileMenuContainer = styled.div<{ menuActive?: boolean }>`
   background: #fff;
   position: fixed;
   height: calc(100vh - 7rem);
   width: 100;
   transition: right 0.3s ease-in-out 0s;
   right: -300px;
   width: 300px;
   border-left: 1px solid grey;

   ${({ menuActive }) =>
      menuActive &&
      css`
         right: 0px;
      `};
`;

export const MenuNavigationContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: flex-start;
   margin: 0px 0 20px 0;
   position: relative;
   padding: 15px;
   background-color: #f5f5f5;
   cursor: pointer;

   .menu-text {
      color: #000;
      opacity: 1;
   }

   .left-icon {
      font-size: 25px;
      opacity: 0.3;
   }
`;

export const MobileUserProfileMenuBody = styled.div`
   color: red;
   display: flex;
   flex-direction: column;
   padding: 0 15px;

   .menu-link {
      text-decoration: none;
      color: #666;
      margin-bottom: 20px;
      font-size: 15px;
      font-weight: 500;
   }
`;

export const MenuIconContainer = styled.div`
   display: none !important;
   position: absolute;
   top: 100px;
   left: 20px;

   @media (max-width: 920px) {
      display: inline !important;
   }

   .menu-icon {
      font-size: 2rem;
      color: ${({ theme }) => theme.colors?.primary};
   }
`;

export const AdvancedFilter = styled.div`
   display: flex;
   gap: 70px;
   align-items: center;
   margin-right: 100px;
`;

export const InnerFilter = styled.div`
   display: flex;
   cursor: pointer;
   gap: 10px;
   align-items: center;
   @media screen and (max-width: 1190px) {
      display: none;
   }
`;
export const RadiusButton = styled.div`
   button {
      border-radius: 8px;
   }
   @media screen and (max-width: 1190px) {
      width: 100%;
   }
`;

export const CountryLinks = styled.div<{ menuActive?: boolean }>`
   display: flex;
   align-items: center;
   margin-left: 5px;

   .ReactFlagsSelect-module_selectBtn__19wW7 {
      padding: 0px;
      border: none;
   }
   .ReactFlagsSelect-module_selectBtn__19wW7:focus {
      outline: none;
   }

   @media screen and (max-width: 1190px) {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0px;
      padding: 12px;
      border-bottom: 1px solid #e2e1e5;

      a {
         display: block;
      }

      ${({ menuActive }) => menuActive && css``}
   }
`;

//second navbar

export const Wrapper = styled.div`
   padding-top: 60px;
   width: 100%;
   @media screen and (max-width: 1190px) {
      display: none;
   }
`;

export const NavWithShapes = styled.div`
   position: relative;
   width: 100%;
   height: 60px;
   overflow: hidden;
   background-color: #fff;
   border-bottom: 4px solid #3578e5;

   &::before,
   &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 15%;
      background-color: #3678e5;
   }

   &::before {
      clip-path: polygon(0% 0%, 47% 0, 100% 100%, 100% 100%, 0 100%);
      left: 0;
   }

   &::after {
      clip-path: polygon(50% 0, 100% 0%, 100% 100%, 38% 100%, 0% 100%);
      right: 0;
   }
`;

export const NavContent = styled.div`
   display: flex;
   justify-content: center;
   padding: 23px;
`;

export const BecomeAVendor = styled.h1`
   color: #3578e5;
   font-size: 16px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   text-decoration: underline;
   cursor: pointer;
   margin-right: 20px;
   @media screen and (max-width: 1190px) {
      padding: 15px;
      border-bottom: 1px solid #e2e1e5;
   }
`;
