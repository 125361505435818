import styled from "styled-components";
import { responsive } from "theme/reponsive";

export const BrandImageStyledContainer = styled.div`
   width: 180px;
   cursor: pointer;
`;

export const BrandImageStyledComponent = styled.img`
   width: 100%;
   object-fit: contain;
`;

export const MarketBrandImageStyledContainer = styled.div`
   width: 200px;
   cursor: pointer;
   ${responsive("$small")`
      width: 150px;
   `}
`;
