import link from "next/link";
import styled from "styled-components";

export const UserDropDown = styled.div`
   /* padding: 1.7rem 0rem; */
   max-width: 290px;
   min-width: 290px;
   position: absolute;
   background: #fff;
   right: 0px;
   top: 0px;
   z-index: 900999;
   box-shadow: 0px 0.4px 9px 6px rgb(0 0 0 / 8%);
   transition: transform 300ms ease-in-out;
   height: auto;
   border-radius: 5px;

   &::after {
      position: absolute;
      top: -11px;
      right: 5px;
      content: "";
      width: 0;
      height: 0;
      border-left: 17px solid transparent;
      border-right: 17px solid transparent;
      border-bottom: 20px solid #f5f5f5;
      border-radius: 10px;
   }
`;

export const UserWrapper = styled.div`
   display: grid;
   grid-template-columns: 45px 1fr;
   justify-content: space-between;
   align-items: center;
   column-gap: 15px;
   padding: 1.5rem 1.9rem;
   background-color: #f5f5f5;
`;

export const UserIconWrapper = styled.div`
   position: relative;
`;
export const UserIcon = styled.p`
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   border: none;
   background: #3578e5;
   font-size: 15px;
   font-weight: 600;
   min-height: 45px;
   min-width: 45px;
   color: white !important;
`;

export const NameWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-self: center;
   white-space: nowrap;

   h4 {
      font-weight: 500;
      font-size: 16px;
   }

   p,
   h4 {
      line-height: 2.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      width: 190px;
   }

   p {
      color: #999;
      font-size: 13px;
      font-weight: 400;
   }
`;

export const DataWrapper = styled.div`
   /* margin-top: 15px; */
   border-top: 1px solid #efefef;
   border-bottom: 1px solid #efefef;
   padding: 8px 0;

   .innerLink {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 1rem 1.5rem;
      font-weight: 500;
      cursor: pointer;

      &:hover {
         background-color: #efefef;
      }

      p {
         padding-left: 1.3rem;
         color: #666;
         line-height: 2.3rem;
         font-size: 1.4rem;
         cursor: pointer;
      }
   }
`;

export const DataItem = styled(link)`
   display: flex;
   align-items: center;
   justify-content: flex-start;
   padding: 1rem 1.5rem;
   font-weight: 500;
   cursor: pointer;
`;

export const OtherMenuLinks = styled.div`
   color: #666;
   line-height: 2rem;
   font-size: 1.4rem;
   padding: 8px 0;
   font-weight: 500;

   p {
      cursor: pointer;
      padding: 1rem 1.5rem;
      color: #666;

      &:hover {
         background-color: #efefef;
      }
   }
`;
