import { getSession } from "utils/auth";

const { isAdmin, user } = getSession();

export const userModalData = [
   {
      text: "My Account",
      img: "/icons/user.svg",
      link: isAdmin || user?.type === "loan_manager" ? "/admin/user/account" : "/account",
   },
   !isAdmin && user?.type !== "loan_manager"
      ? {
           text: "Applications",
           img: "/icons/bag.svg",
           link: "/account/applications",
        }
      : "",
   {
      text: "Change Password",
      img: "/icons/setings.png",
      link:
         isAdmin || user?.type === "loan_manager"
            ? "/admin/user/change-password"
            : "/account/change-password",
   },
];
