import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Link from "next/link";
import { getSession } from "utils/auth";
import {
   MobileUserProfileMenuContainer,
   MenuNavigationContainer,
   MobileUserProfileMenuBody,
} from "./navbar.style";

interface IMobileUserProfileMenu {
   userProfileMenuClicked: boolean;
   setUserProfileMenuClicked: (x?: boolean) => {};
}

export const MobileUserProfileMenu = ({
   userProfileMenuClicked,
   setUserProfileMenuClicked,
}: IMobileUserProfileMenu) => {
   const { isAdmin } = getSession();

   return (
      <MobileUserProfileMenuContainer menuActive={userProfileMenuClicked}>
         <MenuNavigationContainer onClick={() => setUserProfileMenuClicked(false)}>
            <ChevronLeft className="left-icon" /> <span className="menu-text">Menu</span>
         </MenuNavigationContainer>
         <MobileUserProfileMenuBody>
            <Link href={isAdmin ? "/admin/user/account" : "/account"}>
               <a className="menu-link">My Account</a>
            </Link>
            {!isAdmin && (
               <Link href="/account/applications">
                  <a className="menu-link">Applications</a>
               </Link>
            )}
            <Link href={isAdmin ? "/admin/user/change-password" : "/change-password"}>
               <a className="menu-link">Change Password</a>
            </Link>
         </MobileUserProfileMenuBody>
      </MobileUserProfileMenuContainer>
   );
};
