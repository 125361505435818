import { forwardRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchOutlined from "@material-ui/icons/SearchOutlined";
import { ButtonStyledComponent } from "./button.style";
import { IButtonProps } from "./i-button";

const Button: React.FC<IButtonProps> = forwardRef<HTMLButtonElement, IButtonProps>(
   (
      {
         isLoading,
         variant,
         width,
         children,
         fullWidth,
         onClick,
         type = "button",
         size,
         search,
         ...rest
      },
      ref: any
   ) => {
      return (
         // <div>
         <ButtonStyledComponent
            search={search}
            size={size}
            fullWidth={fullWidth}
            type={type}
            variant={variant}
            onClick={onClick}
            ref={ref}
            width={width}
            {...rest}
         >
            {search && <SearchOutlined style={{ marginRight: "10px" }} />}
            <span>{children}</span>
            <span>
               {isLoading && (
                  <CircularProgress
                     style={{ marginLeft: "10px" }}
                     color="inherit"
                     thickness={5}
                     size={13}
                  />
               )}
            </span>
         </ButtonStyledComponent>
         // </div>
      );
   }
);

Button.displayName = "Button";

export { Button };
